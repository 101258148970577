import get from 'lodash/get';
import pick from 'lodash/pick';

import { licensesTypes } from './users';

export const USER_STATUS_CREATED = 'created';
export const USER_STATUS_INVITED = 'invited';
export const USER_STATUS_ACCEPTED_INVITATION = 'accepted_invitation';
export const USER_STATUS_PROFILE = 'profile';
export const USER_STATUS_ACTIVE = 'active';

export function parseUserFromEngine(data = {}) {
  return {
    id: data.id || null,
    firstName: data.first_name || '',
    lastName: data.last_name || '',
    fullName: data.full_name || '',
    title: data.title || '',
    username: data.username || '',
    nickname: data.nickname || '',
    gender: data.gender || '',
    photoUrl: data.photo_url || '',
    phone: data.phone || '',
    mobilePhone: data.mobile_phone || '',
    email: data.email || '',
    region: data.region || '',
    countryCode: data.country_code || 'US',
    emailClient: get(data, 'settings.email_client') || '',
    canReadAllEmails: data.settings && data.settings.can_read_all_emails === '1',
    timezone: data.timezone || '',
    startDayAt: data.start_day_at || '',
    endDayAt: data.end_day_at || '',
    currency: data.currency || '',
    currencySymbol: data.currency_symbol || '',
    profile: data.profile || '',
    crmType: data.crm_type || '',
    crmUserId: data.crm_user_id || '',
    crmOrganizationId: data.crm_organization_id || '',
    salesforceInstanceUrl: data.salesforce_instance_url || '',
    connectedUsers: data.connected_users || [],
    enableLeaderboard: data.enable_leaderboard || false,
    enableNotifications: data.enable_notifications || 0,
    emailToSalesforce: data.email_to_salesforce || '',
    presidentsClubEnabled: data.enable_pclub || false,
    primaryEmail: data.primary_email || null,
    emailSignatureHTML: data.email_signature_html || '',
    spiroScore: data.spiro_score || 0,
    isAdmin: data.is_admin || false,
    isManager: data.is_manager || false,
    isSupermanager: data.is_supermanager || false,
    passwordPresent: data.password_present || false,
    snacks: data.snacks || '',
    loggedIn: data.logged_in || false,
    profileComplete: data.profile_completed || false,
    enableParentAccount: data.enable_parent_account || false,
    status: data.status || '',
    reportsLink: data.reports_link || '',
    zohoZuid: data.settings ? data.settings.zoho_zuid || '' : '',
    zohoTicket: data.settings ? data.settings.zoho_ticket || '' : '',
    monthlyQuota: data.monthly_quota || 0,
    quarterlyQuota: data.quarterly_quota || 0,
    yearlyQuota: data.yearly_quota || 0,
    role: data.role || '',
    forwardTo: data.forward_to || null,
    recording: data.recording || false,
    twilioOrganization: data.twilio_organization,
    custom: data.custom,
    navigationDisplayFields: data.navigation_display_fields || {},
    parentId: data.parent_id,
    zohoEmail: data.zoho_email,
    twilioProfile: data.twilio_profile || null,
    trayIOUser: data.tray_user || null,
    integrationPageEnabled: data.integration_page_enabled || false,
    canDoImport: data.settings && data.settings.can_do_import === '1',
    groupId: data.group_id,
    calendar: data.calendar,
    organization: data.organization,
    addons: data.addons || [],
    licenseType: licensesTypes[data.license_type],
    emailVisibility: data.email_visibility || '',
    logoType: data.logo_type,
    webAlertsDisabled: data.web_alerts_disabled || false,
    voipPopupDisabled: data.voip_popup_disabled || false,
    autoFollowTeamOpportunities: data.settings?.auto_follow_team_opportunities === '1' || false,
    dashboard: data.default_dashboard,
    managerSummaryEnabled: data.manager_summary_email_enabled || false,
    managerSummarySchedule: data.manager_summary_email_schedule || null,
    hasUnreadNotifications: data.has_unread_notifications || false,
    managerSummaryEmailFilter: data.manager_summary_email_filter || null,
    autoFollowTeamOpportunitiesFilter: data.auto_follow_team_opportunities_filter || null,
    showSalesOrderSummary: data.show_sales_order_summary || false,
    externalID: data.external_id || null,
    microsoftUserID: data.microsoft_user_id || null,
    microsoftSync: data.microsoft_sync || false,
    emailConnected: data.email_connected || false,
    executiveSummaryFilter: data.settings
      ? data.settings.executive_summary_exclude_no_activity === '0' || false
      : null,
    autoFollowTeamCompanies: data.auto_follow_team_accounts || false,
    autoFollowTeamCompaniesFilter: data.auto_follow_team_accounts_filter || null,
    unreadGeneralNotificationsCount: data.unread_general_notifications_count,
    unreadPriorityNotificationsCount: data.unread_priority_notifications_count,
    rowHeight: data.row_height || 'standard',
  };
}

export function serialize(user, keys) {
  const s = {
    id: user.id,
    first_name: user.firstName,
    last_name: user.lastName,
    title: user.title,
    phone: user.phone,
    username: user.username,
    password: user.password,
    password_confirmation: user.passwordConfirmation,
    profile: user.profile,
    activity: user.activity,
    enable_leaderboard: user.enableLeaderboard,
    enable_pclub: user.presidentsClubEnabled,
    enable_notifications: user.enableNotifications,
    email_to_salesforce: user.emailToSalesforce,
    snacks: user.snacks,
    currency: user.currency,
    email_client: user.emailClient,
    country_code: user.countryCode,
    custom: user.custom,
    email_signature_html: user.emailSignatureHTML,
    role: user.role,
    parent_id: user.parentId,
    is_admin: user.isAdmin,
    group_id: user.groupId,
    email_visibility: user.emailVisibility,
    web_alerts_disabled: user.webAlertsDisabled,
    voip_popup_disabled: user.voipPopupDisabled,
    external_id: user.externalID,
    default_dashboard: user.dashboard,
    manager_summary_email_enabled: user.managerSummaryEnabled,
    manager_summary_email_schedule: user.managerSummarySchedule,
    auto_follow_team_opportunities: user.autoFollowTeamOpportunities,
    manager_summary_email_filter: user.managerSummaryEmailFilter,
    auto_follow_team_opportunities_filter: user.autoFollowTeamOpportunitiesFilter,
    executive_summary_exclude_no_activity: user.executiveSummaryFilter,
    auto_follow_team_accounts: user.autoFollowTeamCompanies,
    auto_follow_team_accounts_filter: user.autoFollowTeamCompaniesFilter,
    microsoft_user_id: user.microsoftUserID,
    notetaker_enabled: user.notetakerEnabled,
    phone_enabled: user.phoneEnabled,
    license_type: user.licenseType,
    status: user.status,
    reassigned_to_id: user.reassignedTo,
    row_height: user.rowHeight,
    failed_login_attempts: user.failedLoginAttempts,
  };

  if (keys) {
    return pick(s, keys);
  }
  return s;
}

export function shouldDoOnboarding(status) {
  return status !== USER_STATUS_ACTIVE;
}

export function partialParse(data) {
  return {
    title: data.title || '',
    emailVisibility: data.email_visibility || '',
    licenseType: data.license_type,
    groupId: data.group_id,
    notetakerEnabled: data.notetaker_enabled,
    phoneEnabled: data.phone_enabled,
    phone: data.phone,
    parentId: data.parent_id,
    role: data.role,
  };
}

export default {
  parseUserFromEngine,
  serializeUserForEngine: serialize,
};
